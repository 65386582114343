@import url(https://fonts.googleapis.com/css?family=Roboto:400,500|Rubik:500,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*=================== fonts ====================*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Medium Layout: 1280px */
/* Tablet Layout: 768px */
/* Mobile Layout: 320px */
/* Wide Mobile Layout: 480px */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
body {
  line-height: 26px;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #777777;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #000000;
}

.home_page {
  width: 100%;
}

.home_page .home_right_img {
  padding-top: 197px;
}
@media (max-width: 1480px) {
  .home_page .home_right_img img {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 991px) {
  .home_page .home_right_img {
    display: none;
  }
}

.home_page .col-lg-7 {
  vertical-align: middle;
  align-self: center;
}

.home_page .banner_content {
  text-align: left;
}
@media (max-width: 991px) {
  .home_page .banner_content {
    margin-top: 150px;
  }
}
.home_page .banner_content h3 {
  font-size: 40px;
  margin-bottom: 20px;
  position: relative;
}
.home_page .banner_content h3:after {
  content: "";
  width: 410px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 23%;
  background: #000000;
}
@media (max-width: 575px) {
  .home_page .banner_content h3:after {
    display: none;
  }
}
.home_page .banner_content h1 {
  margin-top: 20px;
  font-size: 70px;
  line-height: 60px;
  margin-bottom: 25px;
}
@media (max-width: 1024px) {
  .home_page .banner_content h1 {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .home_page .banner_content h1 {
    font-size: 50px;
  }
}
.home_page .banner_content h5 {
  font-size: 24px;
  margin-bottom: 35px;
}
.home_page .banner_content .primary_btn {
  margin-right: 20px;
}

